import {
  alertError,
  AsyncButton,
  ButtonStyle,
  CardContent,
  CurrencyCode,
  CurrencyField,
  Datepicker,
  InputField,
  InputGroup,
  NumberField,
  Radio,
  SelectField,
  stringsToOptions,
  Switch,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { refreshStripeWebhook } from '../../helpers/APIhelper'
import { PaymentMethod, PaymentProvider } from '../../types/orgTypes'
import {
  Column, IEditOrgProps, Main, paymentMap,
} from './OrgHelper'

const Inline = styled.div`
  display: flex;
  align-items: flex-end;
  *:first-child {
    flex: 1;
  }
  column-gap: 10px;
`

export default function OrgFinance(props: IEditOrgProps): ReactElement {
  const {
    organisation: {
      id,
      paymentMethod,
      gcMandate,
      minimumCommission,
      setupFee,
      commissionFee,
      licenceFee,
      salesCommission,
      salesCommissionUntil,
      paymentProvider,
      stripePublicKey,
      stripeSecretKey,
      stripeWebhookSecret: initStripeWebhookSecret,
      paypalClientId,
      paypalSecret,
      paypalWebhookId,
      trustId,
      trustPassword,
      currencyCode,
      international,
    },
    change,
  } = props
  const [stripeWebhookSecret, setStripeWebhookSecret] = useState(
    initStripeWebhookSecret,
  )

  const paymentProviderOptions = [
    { value: PaymentProvider.Stripe, label: 'Stripe' },
    { value: PaymentProvider.PayPal, label: 'PayPal' },
    { value: PaymentProvider.Trust, label: 'Trust' },
  ]

  const paymentMethodOptions = [
    { value: PaymentMethod.GoCardless, label: 'GoCardless' },
    { value: PaymentMethod.Manual, label: 'Manual' },
  ]

  const handleRefresh = async (): Promise<void> => {
    if (!id) return

    const res = await refreshStripeWebhook({ id })

    if (!res || !res.stripeWebhookSecret) {
      alertError('Error refreshing secret')
      return
    }

    setStripeWebhookSecret(res.stripeWebhookSecret)
  }

  return (
    <CardContent>
      <Main>
        <Column>
          <h3>End customer payment</h3>
          <Radio
            label="System"
            options={paymentProviderOptions}
            onChange={(value): void => change({ paymentProvider: value })}
            value={paymentProvider}
          />
          {paymentProvider === PaymentProvider.Stripe && (
            <>
              <InputField
                label="Stripe publishable key"
                placeholder="Add key..."
                onChange={(value): void => change({ stripePublicKey: value })}
                value={stripePublicKey}
              />
              <InputField
                label="Stripe secret key"
                placeholder="Add key..."
                onChange={(value): void => change({ stripeSecretKey: value })}
                value={stripeSecretKey}
              />
              {stripeWebhookSecret && (
                <Inline>
                  <InputField
                    label="Stripe webhook secret (view only)"
                    value={stripeWebhookSecret}
                    disabled={true}
                  />
                  <AsyncButton
                    buttonStyle={ButtonStyle.Secondary}
                    onClick={handleRefresh}
                  >
                    Refresh
                  </AsyncButton>
                </Inline>
              )}
            </>
          )}
          {paymentProvider === PaymentProvider.PayPal && (
            <>
              <InputField
                label="PayPal client ID"
                placeholder="Add client ID..."
                onChange={(value): void => change({ paypalClientId: value })}
                value={paypalClientId}
              />
              <InputField
                label="PayPal secret"
                placeholder="Add secret..."
                onChange={(value): void => change({ paypalSecret: value })}
                value={paypalSecret}
              />
              {paypalWebhookId && (
                <InputField
                  label="PayPal webhook ID (view only)"
                  value={paypalWebhookId}
                  disabled={true}
                />
              )}
            </>
          )}
          {paymentProvider === PaymentProvider.Trust && (
            <>
              <InputField
                label="Trust site reference"
                placeholder="Add reference..."
                onChange={(value): void => change({ trustId: value })}
                value={trustId}
              />
              <InputField
                label="Trust password"
                placeholder="Add password..."
                onChange={(value): void => change({ trustPassword: value })}
                value={trustPassword}
              />
            </>
          )}
          <SelectField
            label="Currency"
            placeholder="Select currency..."
            options={stringsToOptions([CurrencyCode.GBP, CurrencyCode.EUR])}
            value={{ value: currencyCode, label: currencyCode }}
            onChange={(option): void => change({ currencyCode: option?.value })}
          />
          <Switch
            label="International (no UK VAT)"
            value={international || false}
            switchAction={(): void => change({ international: !international })}
            flipLabel={true}
          />
          <h3>Sales commission (inactive)</h3>
          <InputGroup>
            <NumberField
              label="% Amount"
              onChange={(value): void => change({ salesCommission: value || undefined })}
              value={salesCommission || null}
              disabled={true}
            />
            <Datepicker
              label="Pay until"
              onChange={(date): void => change({ salesCommissionUntil: moment(date).unix() })}
              value={
                salesCommissionUntil ? moment.unix(salesCommissionUntil) : null
              }
              disabled={true}
            />
          </InputGroup>
        </Column>
        <Column>
          <h3>One Tree fees</h3>
          <SelectField
            placeholder="Select method..."
            label="Client payment method"
            options={paymentMethodOptions}
            value={{
              value: paymentMethod,
              label: paymentMethod && paymentMap.get(paymentMethod),
            }}
            onChange={(option): void => change({ paymentMethod: option?.value })}
          />
          {paymentMethod === PaymentMethod.GoCardless && (
            <InputField
              placeholder="Add mandate..."
              label="GoCardless mandate"
              value={gcMandate}
              onChange={(value): void => change({ gcMandate: value })}
            />
          )}
          <InputGroup>
            <NumberField
              label="Commission %"
              onChange={(value): void => change({
                commissionFee: value || value === 0 ? value : undefined,
              })}
              value={
                commissionFee || commissionFee === 0 ? commissionFee : null
              }
              minimumValue={0}
            />
            <CurrencyField
              label="Monthly license fee"
              currency={CurrencyCode.GBP}
              onChange={(value): void => change({
                licenceFee: value ? Number(value) : undefined,
              })}
              initValue={
                licenceFee || licenceFee === 0
                  ? licenceFee.toString()
                  : undefined
              }
            />
          </InputGroup>
          <InputGroup>
            <CurrencyField
              label="Min. commission"
              currency={CurrencyCode.GBP}
              onChange={(value): void => change({ minimumCommission: value })}
              initValue={minimumCommission}
            />
            <CurrencyField
              label="Set up fee"
              currency={CurrencyCode.GBP}
              onChange={(value): void => change({ setupFee: value })}
              initValue={setupFee}
            />
          </InputGroup>
        </Column>
      </Main>
    </CardContent>
  )
}
